import { createContext, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { BreadCrumbBox } from './BreadCrumb.style';

export type IBreadcrumbContext = {
  handleRouteChange: (currentPath: string, searchParams: string) => void;
  breadCrumbItens: IRouteBreadcrumb[];
  handleClickBreadItem: (index: number) => void;
  handleBackButton: () => void;
  routes: IRouteBreadcrumb[];
  updateSearchParams: (currentPath: string, searchParams: string) => void;
};

let store: IBreadcrumbContext;

export const BreadcrumbContext = createContext(store);
export const useBreadcrumbContext = () => useContext(BreadcrumbContext);

export interface IRouteBreadcrumb {
  label: string;
  path: string;
}

export const BreadcrumbProvider = ({ children }) => {
  const [breadCrumbItens, setBreadCrumbItens] = useState<IRouteBreadcrumb[]>([]);
  const { formatMessage } = useIntl();

  const routes: IRouteBreadcrumb[] = [
    {
      label: formatMessage({ id: 'ticketPriorization.title' }),
      path: '/beescare/l2-tickets/',
    },
    {
      label: formatMessage({ id: 'ticketDetails.title' }),
      path: '/beescare/l2-tickets/ticket/',
    },
    {
      label: formatMessage({ id: 'ticketList.title' }),
      path: '/beescare/l2-tickets/ticket-list',
    },
    {
      label: formatMessage({ id: 'ticketHistory.title' }),
      path: '/beescare/l2-tickets/ticket-history',
    },
  ];

  useEffect(() => {
    findDuplicates(breadCrumbItens);
  }, [breadCrumbItens]);

  const getRoute = (path: string, searchParams) => {
    if (path.includes(routes[1].path)) {
      routes[1].path = path;
      return routes[1];
    }
    if (path.includes(routes[2].path)) {
      routes[2].path = path + searchParams;
      return routes[2];
    }
    if (path.includes(routes[3].path)) {
      routes[3].path = path + searchParams;
      return routes[3];
    }
    const myRoute = routes.find((route) => route.path.includes(path));
    return myRoute?.label === formatMessage({ id: 'ticketPriorization.title' }) ? null : myRoute;
  };

  function findDuplicates(arr) {
    const myArr = arr.map((element, index) => {
      // eslint-disable-next-line no-param-reassign
      element.id = index;
      return element;
    });

    const duplicateIds = myArr
      .map((e) => e.label)
      .map((e, i, final) => final.indexOf(e) !== i && i)
      .filter((obj) => myArr[obj])
      .map((e) => myArr[e]);

    if (duplicateIds.length) {
      removeNextItensByIndex(duplicateIds[0].id - 1);
    }
  }

  const handleRouteChange = (currentPath, searchParams) => {
    const newRoute = getRoute(currentPath, searchParams);
    if (newRoute) {
      setBreadCrumbItens((oldArray) => [...oldArray, newRoute]);
    }
  };

  const handleBackButton = () => {
    setBreadCrumbItens((oldArray) => oldArray.slice(0, -2));
  };

  const removeNextItensByIndex = (index: number) => {
    breadCrumbItens.splice(index, breadCrumbItens.length);
  };

  const handleClickBreadItem = (index) => {
    removeNextItensByIndex(index);
  };

  const updateSearchParams = (currentPath, searchParams) => {
    const newRoute = getRoute(currentPath, searchParams);
    if (newRoute) {
      setBreadCrumbItens((oldArray) =>
        oldArray.map((content) => {
          if (content.label === newRoute.label) return newRoute;
          return content;
        })
      );
    }
  };

  return (
    <BreadCrumbBox>
    <BreadcrumbContext.Provider
      value={{
        handleRouteChange,
        breadCrumbItens,
        handleClickBreadItem,
        handleBackButton,
        routes,
        updateSearchParams,
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
    </BreadCrumbBox>
  );
};
