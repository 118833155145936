// @ts-nocheck
import { Grid } from '@hexa-ui/components';
import { createGenerateClassName, StylesProvider } from '@mui/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createFeatureToggleServiceV2 } from 'admin-portal-shared-services';
import { createBrowserHistory } from 'history';
import { useEffect } from 'react';
import { Provider as ReactBusProvider } from 'react-bus';
import { BrowserRouter } from 'react-router-dom';
import useHotjar from 'react-use-hotjar';
import { AuthProvider } from './contexts/AuthProvider';
import { BreadcrumbProvider } from './contexts/BreadcrumbProvider';
import { EnvProvider, IEnvContext } from './contexts/EnvProvider';
import ToastProvider from './contexts/ToastProvider/ToastProvider';
import IntlProvider from './i18n/provider';
import Routes from './Router';
import { PROJECT_NAME_OPTIMIZELY } from './services/constants';
import GlobalStyles from './styles/GlobalStyles';

export const App = (props: IEnvContext) => {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'admin-portal-beescare-l2-tickets-mfe',
    seed: 'admin-portal-beescare-l2-tickets-mfe',
  });

  const history = createBrowserHistory();
  let prevPath = null;

  history.listen((location) => {
    if (location.pathname !== prevPath) {
      prevPath = location.pathname;
    }
  });
  const queryClient = new QueryClient();

  const { initHotjar } = useHotjar();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'test') {
      // eslint-disable-next-line no-console
      initHotjar(props.hotjarID, 6, false, console.info);
    }
  }, [initHotjar]);

  useEffect(() => {
    createFeatureToggleServiceV2(PROJECT_NAME_OPTIMIZELY, props.optimizelyKey);
  }, []);

  return (
    <ReactBusProvider>
      <EnvProvider env={props}>
        <StylesProvider generateClassName={generateClassName}>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <IntlProvider>
                <BreadcrumbProvider>
                  <Grid.Container type="fluid" sidebar>
                    <BrowserRouter>
                      <ToastProvider>
                        <Routes />
                        <GlobalStyles />
                      </ToastProvider>
                    </BrowserRouter>
                  </Grid.Container>
                </BreadcrumbProvider>
              </IntlProvider>
            </AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </StylesProvider>
      </EnvProvider>
    </ReactBusProvider>
  );
};

export default App;
