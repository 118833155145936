export const DEFAULT_DATETIME_FORMAT = 'dd/MM/yyyy HH:mm';

export const DEFAULT_DATE_FORMAT = 'P';

export const LONG_LOCALIZED_DATE_AND_TIME = 'PPPp';

export const LOCALIZED_DATE = 'PP';

export const LOCALIZED_DATE_PTBR_ES = `dd 'de 'MMM', 'yyyy`;

export const LOCALIZE_DATE_DAYS_WEEK = `eee, PP`; // Tue, Oct 17, 2023

export const DATE_DAYS_WEEK_PTBR_ES = `eeeeee, dd 'de' MMM 'de' yyyy`; // Ter, 17 de out de 2023 - Ma, 17 de oct de 2023

export const TIME_FORMAT_12H = `hh':'mm aaa`;

export const TIME_FORMAT_12H_PT_BR = `hh'h'mm aaa`;

export const TIME_FORMAT_24H = `HH':'mm`;

export const TIME_FORMAT_24H_PT_BR = `HH'h'mm`;

export const LONG_DATE_AND_TIME_12H_PTBR = `PPP 'às' hh'h'mm aaa`;

export const LONG_DATE_AND_TIME_12H_ENUS = `PPP 'at' hh':'mm aaa`;

export const LONG_DATE_AND_TIME_12H_ES = `PPP 'a las' hh':'mm aaa`;

export const LONG_DATE_AND_TIME_24H_PTBR = `PPP 'às' HH'h'mm`;

export const LONG_DATE_AND_TIME_24H_ENUS = `PPP 'at' HH':'mm`;

export const LONG_DATE_AND_TIME_24H_ES = `PPP 'a las' HH':'mm`;

export const DATE_AND_TIME_12H = `P hh:mm aaa`;

export const DATE_AND_TIME_12H_PTBR = `P hh'h'mm aaa`;

export const DATE_AND_TIME_24H = `P HH:mm`;

export const DATE_AND_TIME_24H_PTBR = `P HH'h'mm`;

export const LOCALIZE_DATE_DAYS_WEEK_PTBR = `eee, d 'de' LLLL, y`;

export const LOCALIZE_DATE_DAYS_WEEK_ES = `eee, d 'de' LLL, y`;

export const COMBINATION_OF_DATE_AND_TIME = 'PPPpp';

export const COMBINATION_DATE_AND_TIME_12H_PTBR = `PPP 'às' hh'h'mm':'ss aaa`;

export const COMBINATION_DATE_AND_TIME_12H_ENUS = `PPP 'at' hh':'mm':'ss aaa`;

export const COMBINATION_DATE_AND_TIME_12H_ES = `PPP 'a las' hh':'mm':'ss aaa`;

export const COMBINATION_DATE_AND_TIME_24H_PTBR = `PPP 'às' HH'h'mm':'ss'`;

export const COMBINATION_DATE_AND_TIME_24H_ENUS = `PPP 'at' HH':'mm':'ss'`;

export const COMBINATION_DATE_AND_TIME_24H_ES = `PPP 'a las' HH':'mm':'ss`;

export const LONG_WEEK_DATE_AND_TIME_12H_PTBR = `eeeeee', ' PPP 'às' hh'h'mm`; // Qua, 29 de junho de 2022 às 04h02

export const LONG_WEEK_DATE_AND_TIME_12H_ENUS = `eee', ' PP 'at' hh':'mm aaa`; // Wed, Jun 29, 2022 at 04:02 pm

export const LONG_WEEK_DATE_AND_TIME_12H_ES = `eeeeee', ' PPP 'a las' hh':'mm aaa`; // Mi, 29 de junio de 2022 a las 04:02 pm

export const LONG_WEEK_DATE_AND_TIME_24H_PTBR = `eeeeee', ' dd 'de' MMM 'de 'yyy 'às' HH'h'mm`; // Qua, 29 de junho de 2022 às 16h02

export const LONG_WEEK_DATE_AND_TIME_24H_ENUS = `eee', ' PP 'at' HH':'mm`; // Wed, Jun 29, 2022 at 16:02

export const LONG_WEEK_DATE_AND_TIME_24H_ES = `eeeeee', ' PPP 'a las' HH':'mm`; // Mi, 29 de junio de 2022 a las 16:02

export const SLA_BREACH_TIME_IN_HOURS = 48; // 48h

export const APP_TRACE_ID = 'BeesCare-l2';

export const PROJECT_NAME_OPTIMIZELY = 'BEES Care - Beats';

export const ACCOUNT_ENDPOINT = '/api/bees-care-hub-bff/account/';

export const GROUPS_ENDPOINT = '/api/bees-care-hub-bff/groups/';

export const GET_COMMENTS_ENDPOINT = '/api/v1/tickets/comments/ticket/';

export const GET_TICKET_DETAILS_ENDPOINT = '/api/v1/tickets/';

export const POST_TICKET_COMMENT_ENDPOINT = '/api/v1/ticket-relay/comments/ticket/';

export const GET_DYNAMIC_FORMS_ENDPOINT = '/api/care-dynamic-forms/ticket/';

export const GET_BULK_RESOLUTION_DYNAMIC_FORMS_ENDPOINT =
  '/api/bees-care-hub-bff/dynamic-forms/categories/{categoryId}/fields-bulk';

export const GET_TICKETS = '/api/bees-care-hub-bff/tickets/user-group-context';

export const GET_TICKET_BY_SEARCH = '/api/bees-care-hub-bff/tickets/external-id/';

export const GET_TICKETS_BY_ACCOUNT = '/api/bees-care-hub-bff/tickets';

export const GET_USER_GROUPS = '/api/bees-care-hub-bff/groups';

export const GET_GROUPS = '/api/bees-care-hub-bff/groups/by-name';

export const GET_FORMS_LIST = '/api/bees-care-hub-bff/dynamic-forms/categories';

export const ATTACHMENT_UPLOAD_ENDPOINT = '/api/tickets/attachments';

export const RESOLUTION_METRICS_ENDPOINT = '/api/bees-care-hub-bff/tickets/resolution-metrics';

export const EXPIRING_TICKETS_ENDPOINT = '/api/bees-care-hub-bff/tickets/sla-metrics';

export const VISIT_ENDPOINT = '/api/bees-care-hub-bff/account/{accountId}/visit';

export const LAST_ORDER_ENDPOINT = '/api/bees-care-hub-bff/accounts/{taxId}/last-order';

export const MAX_FILE_SIZE_IN_BYTES = 50 * 1024 * 1024; // 50 MB

export const TICKET_UPDATES_REFRESH_IN_MILLISECONDS = 600000; // 10 min

export const PAGE_SIZE = 10;

export const PUT_TICKETS = '/api/bees-care-hub-bff/tickets/bulk/';

export const HOME_ROUTE = '/beescare/l2-tickets';
