import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { IntlProvider as NativeProvider } from 'react-intl';
import enUS from './languages/en';
import esMX from './languages/es';
import ptBR from './languages/pt';

const DEFAULT_LANGUAGE = 'en-US';
export type MessageMap = {
  [key: string]: { [key: string]: string | MessageMap } | string;
};

export const messages: { [language: string]: MessageMap } = {
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-419': esMX,
};

export const IntlProvider = ({ children }): JSX.Element => {
  const { preferredLanguage } = usePreferredLanguageV2();
  const mergedMessages = mergeMessages(messages, preferredLanguage);

  return (
    <NativeProvider
      locale={preferredLanguage}
      defaultLocale={DEFAULT_LANGUAGE}
      messages={mergedMessages}
    >
      {children}
    </NativeProvider>
  );
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
export const flattenObject = (ob: any): any => {
  const toReturn: { [key: string]: any } = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      // eslint-disable-next-line guard-for-in
      for (const x in flatObject) {
        toReturn[`${i}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};
/* eslint-enable */

export const mergeMessages = (messagesInput: MessageMap, selectedLocale: string): any => {
  const defaultMessages = flattenObject(messagesInput[DEFAULT_LANGUAGE]);
  const localeMessages = flattenObject(messagesInput[selectedLocale]);
  return { ...defaultMessages, ...localeMessages };
};

export default IntlProvider;
