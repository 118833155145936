import MessageMap from '../i18n.d';

const es: MessageMap = {
  ticketList: {
    title: 'Tickets para resolver',
    refreshButton: 'Recargar',
    ticketsHaveUpdates: 'Sus tickets tienen actualizaciones. Haga clic en Recargar.',
    filter: {
      title: 'Filtrado por',
      clearAll: 'Borrar todo',
      filters: {
        groups: 'Grupos',
        forms: 'Form',
        formsLabels: {
          placeholderSelect: 'Seleccione un formulario',
          message: 'Permitido enviar en pila (excepto "Todos los formularios")',
          allForms: 'Todos los Formularios',
          note: 'Permite enviar en pila.',
        },
        formsSubcategory: 'Subcategoría',
        externalStatuses: 'Status',
        statusType: {
          new: 'Nuevo',
          onHold: 'En espera',
          open: 'Abierto',
          pending: 'Pendiente',
        },
        dateBreachAt: 'Fecha de expiración',
        startDate: 'Fecha inicial',
        endDate: 'Fecha final',
        tooltip: {
          formsSubcategory: 'Some forms allow to submit tickets in bulk',
          disabledFilter: 'No se pueden editar los filtros en envíos colectivos.',
        },
      },
      errors: {
        endDateMustBeAfter: 'La fecha final debe ser después de la fecha inicial',
        maxRange7days: 'Intervalo máximo de fechas: 7 días',
      },
    },
  },
  tableColumns: {
    status: 'Status',
    slas: 'SLA',
    subject: 'Asunto',
    id: 'ID',
    organization: 'Organización',
    groupName: 'Grupo',
    ticketForm: 'Formulario',
    createDate: 'Creado el',
    assignee: 'Responsable',
    trt: 'TRT',
    vsSla: 'Correlación SLA',
    viewLinkTooltip: 'Ctrl+click para abrir en una nueva pestaña',
  },
  pagination: {
    of: 'de',
  },
  ticketStatus: {
    new: 'Nuevo',
    open: 'Abierto',
    pending: 'Pendiente',
    hold: 'En espera',
    solved: 'Resuelto',
    closed: 'Cerrado',
    inProgress: 'Abierto',
  },
  ticketDetails: {
    title: 'Detalles del ticket',
    noAccess: 'Parece que no tenés permiso para acceder a los detalles de este ticket.',
    formInfo: 'Información del formulario',
    infoNotAvailable: 'Información no disponible.',
    macro: {
      applyMacro: 'Aplicar macro',
    },
    ordersStatus: {
      orderPlaced: 'Pedido realizado',
      processing: 'Confirmado',
      processingWithModifications: 'Confirmado con modificaciones',
      billed: 'Facturado',
      outForDelivery: 'En camino',
      delivered: 'Entregado',
      partiallyDelivered: 'Parcialmente entregado',
      canceled: 'Cancelado',
      declined: 'Envío rechazado',
      cancellationPending: 'Cancelación pendiente',
      paymentPending: 'Pago pendiente',
      readyForPickup: 'Listo para recoger',
    },
    pocInformation: {
      title: 'Información del PDV',
      name: 'Nombre',
      pocStatus: 'Estado',
      challengeIds: 'Código del PDV',
    },
    pocContact: {
      title: 'Contacto del PDV',
      ownerName: 'Propietario del PDV',
      ownerEmail: 'Correo electrónico',
      telephone: 'Teléfono',
      taxId: 'Tax ID',
    },
    addressDistribution: {
      title: 'Dirección y distribución',
      address: 'Dirección',
      city: 'Ciudad',
      ddcName: 'Nombre del centro de distribución',
    },
    salesRepresentative: {
      title: 'Representante de ventas',
      agentName: 'Nombre del representante de ventas',
      plannedVisit: 'Visita prevista',
      lastVisitStatus: 'Último estado de la visita',
      lastVisit: 'Fecha de la última visita',
    },
    lastOrder: {
      title: 'Último pedido',
      orderNumber: 'ID del pedido',
      orderDate: 'Fecha del pedido',
      amount: 'Cantidad',
      status: 'Estado',
      source: 'Origen',
      creationDate: 'Fecha del pedido',
      deliveryDate: 'Entrega',
      paymentMethod: 'Método de pago',
      totalAmount: 'Cantidad total',
      showProduct: 'Mostrar productos',
      noOrders: 'No se han realizado pedidos aún.',
      modal: {
        orderList: 'Lista de productos',
        image: 'Imagen',
        productName: 'Nombre del producto',
        amount: 'Cantidad',
        unitValue: 'Precio unitario',
        discount: 'Descuento',
        totalValue: 'Total',
      },
    },
    ticketInfo: {
      title: 'Información del ticket',
      id: 'ID del ticket',
      updatedAt: 'Última actividad',
      requester: 'Solicitante',
    },
    ticketFields: {
      assignee: 'Responsable',
      form: 'Formulario',
      resolutionGroup: 'Grupo de resolución',
      reassign: 'Reasignar',
      ticketUnassigned: 'Ticket no asignado.',
      takeIt: 'Asignar a mí',
    },
    sla: {
      solveIn: 'Resolver en',
      slaPaused: 'SLA Pausado',
      expiration: 'Fecha de vencimiento: ',
    },
  },
  bulkResolution: {
    modal: {
      openModalButton: 'Enviar a granel',
      title: 'Rellene los campos para enviar',
      subTitle: 'Las entradas seleccionadas se enviarán de forma masiva.',
      form: 'Formulario',
      subCategory: 'Sub Categoría',
      numberOfTickets: 'Número de tickets seleccionadas',
      cancel: 'Cancelar',
      submit: 'Enviar',
      toast: {
        info: '{tickets} tickets enviadas correctamente.',
        error: `No se pudieron enviar los tickets. Inténtalo de nuevo`,
      },
      notAllowed: {
        title: 'Acción no permitida',
        subTitle:
          'No tenés permiso para editar los tickets siguientes. Podés continuar sin estos tickets o volver e intentar editar otra selección.',
        cancel: 'Volver',
        submit: 'Continuar sin la selección',
        allTicketsTitle: 'Acción no permitida',
        allTicketsSubTitle: `No tenés permiso para editar ninguno de los tickets seleccionados. Intentalo de nuevo con otros tickets.`,
        allTicketsCancel: 'Entendido',
      },
    },
  },
 
  visitStatus: {
    OPEN: 'Abierto',
    PENDING: 'Pendiente',
    CLOSED: 'Cerrado',
    NOT_COMPLETED: 'No concluído',
    CANCELLED: 'Cancelado',
  },
  comments: {
    internal: 'Interna',
    public: 'Pública',
    publicResponse: 'Respuesta pública',
    internalNote: 'Nota interna',
  },
  chooseTicketStatus: 'Status del ticket',
  submitButton: 'Enviar',
  client: 'Cliente',
  toastMessages: {
    info: 'Actualizaciones enviadas exitosamente.',
    error: 'Algo salió mal. Por favor, intenta nuevamente. ',
    searchTicketError: 'No results found. Try searching for another ticket number.',
    unauthorizedSearchTicketError:
      'No tenés acceso al ticket que buscaste. Buscá otro número de ticket.',
    searchPocError:
      'Este ID tiene más de un POC asociado. Por favor escriba un identificador único.',
    unauthorizedSearchPocError: 'El PDV que buscaste no está disponible para vos. Buscá otro CUIT.',
    fileExceedsSizeLimitError: 'El archivo seleccionado supera el límite de 50MB.',
    fileError: 'Algo salió mal al enviar el archivo(s) {fileNames}. Inténtelo otra vez, por favor.',
    attachmentsWarning:
      'El archivo adjunto cargado se está cargando en el servidor, por lo que tardará unos minutos en verse en el feed de mensajes.',
    reassignGroupSuccess: 'Reasignación enviada con éxito.',
    reassignGroupError: 'La reasignación salió mal. Inténtalo de nuevo.',
    unassociatedVendorIdPoc: 'No tienes acceso al POC buscado.',
    unassociatedVendorIdTicket: 'No tienes acceso al ticket buscado.',
  },
  searchBarTicket: {
    messageTicket: 'Busque un ID de ticket para acceder a él directamente.',
    messagePoc: 'Buscar por ID gubernamental para acceder el PDV directamente.',
    poc: 'PDV',
  },
  filter: {
    title: 'Filtro',
    apply: 'Aplicar',
    cancel: 'Cancelar',
  },
  previewTickets: {
    title: 'Comentarios',
    lastComments: 'Últimos comentarios',
    viewButton: 'Ver ticket',
    totalComments: 'Total de comentarios: ',
    emptyComments: 'No más comentarios',
  },
  noResults: 'No se encontraron resultados',
  loading: 'Cargando…',
  ticketPriorization: {
    title: 'Prioridad de tickets',
    ticketsByType: 'Tickets por tipo',
    allTickets: 'Todos los tickets',
    byGroups: 'Por grupo',
    byForms: 'Por formulario',
    total: 'Total',
    paused: 'En pausa',
    expired: 'Vencidos',
    tmr: 'TRT',
    warning: 'Aténcion',
    neutral: 'Neutral',
    openAll: 'Mostrar todos',
    buttonToTicketsSolve: 'Mostrar tickets',
    days: 'días',
    day: 'día',
    expiringIn7DaysByGroup: 'Venciendo dentro de 7 días',
    today: 'hoy',
    none: 'Ninguno',
    filters: {
      alphabetical: 'A-Z',
      moreTickets: 'Mayor cantidad de tickets',
    },
    tooltipPaused:
      'La cantidad de tickets que tienen el SLA en pausa, con status "pendiente". No se cuentan como tickets vencidos.',
    tooltipTmr:
      'El "tiempo total de resolución" en curso, que es tiempo que pasó entre la creación del ticket y el día de hoy.',
    tooltipExpired: 'La cantidad de tickets vencidos que aún no se resolvieron.',
  },
  ticketHistory: {
    title: 'Historial de tickets del PDV',
    solvedTickets: 'Resuelto',
    unSolvedTickets: 'A resolver',
  },
  pocTicketHistory: {
    bdr: 'Nombre del BDR',
    taxId: 'Tax ID',
    challengeIds: 'Codigo del cliente',
    status: {
      active: 'Activo',
      blocked: 'Bloqueado',
      inactive: 'Inactivo',
    },
    noAccess: 'No tenés acceso al historial de tickets de este PDV. Intentá buscar otro PDV.',
    noTicketsFoundTitle: 'No se encontraron tickets',
    noTicketsFound:
      'Parece que este PDV todavía no tiene tickets. Los tickets futuros se mostrarán acá.',
  },
  multiSelect: {
    itemsSelect: 'ítems seleccionados',
  },
  slaStatus: {
    SLA_ON_TIME: 'Al día',
    SLA_BREACHED: 'Con demora',
    NOT_FOUND: '-',
  },
  messages: {
    noAccess: 'Acceso denegado',
    returnHome: 'Ir a Inicio',
    returnPrevious: 'Ir a Inicio',
  },
};

export default es;
