import { css } from 'styled-components'

export const SmallFontSize = css`
font-size: clamp(0.75rem, 0.4464rem + 0.3571vw, 0.875rem);
`
export const MediumFontSize = css`
font-size: clamp(0.875rem, 0.5714rem + 0.3571vw, 1rem);
`
export const BigFontSize = css`
font-size: clamp(1.5rem, 0.2857rem + 1.4286vw, 2rem);
`
 