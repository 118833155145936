import { Toast } from '@hexa-ui/components';
import React from 'react';

export interface ToastData {
  type: 'success' | 'error' | 'warning' | 'info';
  position?:
  | 'bottom'
  | 'top'
  | 'top-right'
  | 'top-left'
  | 'bottom-right'
  | 'bottom-left';
  message: string;
}

interface ContextData {
  setToastAction: (toastAction: ToastData) => void;
}

export const ToastContext = React.createContext<ContextData>({} as ContextData);

export const useToast = () => React.useContext(ToastContext);

const ToastProvider: React.FC = ({ children }) => {
  const [toastData, setToastData] = React.useState({} as ToastData);
  const [toastOpen, setToastOpen] = React.useState(false);

  const setToastAction = React.useCallback((toastAction: ToastData) => {
    setToastData(toastAction);
    setToastOpen(true);
  }, []);

  const value = React.useMemo(() => ({ setToastAction }), [setToastAction]);

  return (
    <ToastContext.Provider value={value}>
      <Toast.Provider>
        <Toast.Root
          message={toastData.message}
          position={toastData.position ?? 'top-right'}
          type={toastData.type}
          open={toastOpen}
          showCloseButton={true}
          onOpenChange={(value: boolean) => setToastOpen(value)}
          onClose={() => setToastOpen(false)}
        />
        {children}
      </Toast.Provider>
    </ToastContext.Provider>
  );
};

export default ToastProvider;
