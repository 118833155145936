import { useAuthenticationService, usePreferredLanguageV2, useUserMetadata } from 'admin-portal-shared-services';
import { UserInfoToken } from 'admin-portal-shared-services/dist/services/authentication/AuthenticationService.types';
import { Locale } from 'date-fns';
import { enUS, es, ptBR } from 'date-fns/locale';
import React from 'react';
import { countryList } from '../../services/CountryData';

export interface Vendor {
  id: string | undefined;
  country: string | undefined;
  displayName: string | undefined;
  serviceModel: string | undefined;
  governmentId: string | undefined;
  abiVendorId: string | undefined;
}

export interface IUserMetadata {
  avatarUrl?: string | undefined;
  userId: string | undefined;
  acceptedTerms?: string[] | undefined;
  accounts?: string[] | undefined;
  supportedCountries?: string[] | undefined;
  profile?:
  | {
    preferredLanguage?: string;
    preferredTimeFormat?: string;
    timezone?: string;
  }
  | undefined;
  authorization?:
  | {
    groups?: string[] | undefined;
    scopes?: string | undefined;
  }
  | undefined;
  deliveryCenterIds?: string[] | undefined;
  routeId?: string | undefined;
  vendors?: Vendor[] | undefined;
}

export type IAuthContext = {
  currentUser: UserInfoToken | null;
  locale?: Locale | null;
  data: IUserMetadata | any;
  userCountrySelected?: string;
  setUserCountrySelected?: (e) => void;
  userCountryList: any;
  setUserCountryList: (e) => void;
};

const store: IAuthContext = {
  currentUser: null,
  locale: null,
  data: null,
  userCountrySelected: null,
  setUserCountrySelected: null,
  userCountryList: null,
  setUserCountryList: null,
};

export const AuthContext = React.createContext(store);
export const useAuthContext = () => React.useContext(AuthContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const authService = useAuthenticationService();
  const currentUser = authService.parseJwt();
  const { data } = useUserMetadata();
  const [userCountryList, setUserCountryList] = React.useState([]);
  const userCountry = localStorage.getItem('userCountry');
  const [userCountrySelected, setUserCountrySelected] = React.useState(userCountry || currentUser?.country)
  const { preferredLanguage } = usePreferredLanguageV2();
  let locale;

  React.useEffect(() => {
    const userSupportedCountry = countryList.filter(country =>
      currentUser.supportedCountries.includes(country.sigla)
    );
    const checkUserCountryIsSupported = userSupportedCountry.some(objeto => objeto.sigla === userCountrySelected);
    if(!checkUserCountryIsSupported){
      setUserCountrySelected(userSupportedCountry[0].sigla);
    }
    setUserCountryList(userSupportedCountry);
  }, []);

  switch (preferredLanguage) {
    case 'pt-BR':
      locale = ptBR;
      break;
    case 'en-US':
      locale = enUS;
      break;
    case 'es-419':
      locale = es;
      break;
    default:
      locale = enUS;
  }

  return <AuthContext.Provider value={{
    currentUser,
    userCountrySelected,
    setUserCountrySelected,
    locale,
    data,
    userCountryList,
    setUserCountryList
  }}>{children}</AuthContext.Provider>;
};
