import { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';

import { useBreadcrumbContext } from './contexts/BreadcrumbProvider';
import { HOME_ROUTE } from './services/constants';

const TicketList = lazy(() => import('./components/pages/TicketList/TicketList'));
const TicketDetails = lazy(() => import('./components/pages/TicketDetails/TicketDetails'));
const TicketHistory = lazy(() => import('./components/pages/TicketHistory/TicketHistory'));
const TicketPriorizationSwitch = lazy(
  () =>
    import(
      './components/pages/TicketPrioritization/components/TicketPrioritizationSwitch/TicketPrioritizationSwitch'
    )
);

function useBreadcrumbLogic() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useState(location.search);
  const { handleRouteChange, updateSearchParams } = useBreadcrumbContext();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [previousPath, setPreviousPath] = useState(location.pathname);

  useEffect(() => {
    if (location.pathname !== currentPath) {
      setPreviousPath(() => currentPath);
      setCurrentPath(() => location.pathname);
      setSearchParams(() => location.search);
    }
  }, [location.pathname]);

  useEffect(() => {
    handleRouteChange(currentPath, searchParams);
  }, [currentPath, previousPath]);

  useEffect(() => {
    updateSearchParams(location.pathname, location.search);
  }, [location.search]);
}

const Router = (): JSX.Element => {
  useBreadcrumbLogic();
  return (
    <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
      <Routes>
        <Route path={HOME_ROUTE} element={<TicketPriorizationSwitch />} />
        <Route path={`${HOME_ROUTE}/ticket/:ticketId`} element={<TicketDetails />} />
        <Route path={`${HOME_ROUTE}/ticket-list`} element={<TicketList />} />
        <Route path={`${HOME_ROUTE}/ticket-history`} element={<TicketHistory />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
