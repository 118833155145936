import MessageMap from '../i18n.d';

const en: MessageMap = {
  ticketList: {
    title: 'Tickets to solve',
    refreshButton: 'Refresh',
    ticketsHaveUpdates: 'Your tickets have updates. Please click on Refresh.',
    filter: {
      title: 'Filtered by',
      clearAll: 'Clear all',
      filters: {
        groups: 'Groups',
        forms: 'Form',
        formsLabels: {
          placeholderSelect: 'Select a form',
          message: 'Allows to submit in bulk (except "All forms")',
          allForms: 'All forms',
          note: 'Allows to submit in bulk.',
        },
        formsSubcategory: 'Subcategory',
        externalStatuses: 'Status',
        statusType: {
          new: 'New',
          onHold: 'On Hold',
          open: 'Open',
          pending: 'Pending',
        },
        dateBreachAt: 'Expiration date',
        startDate: 'Start date',
        endDate: 'End date',
        tooltip: {
          formsSubcategory: 'Some forms allow to submit tickets in bulk',
          disabledFilter: 'Unable to edit filters in bulk submissions.',
        },
      },
      errors: {
        endDateMustBeAfter: 'The end date must be after the start date',
        maxRange7days: 'Max. date range: 7 days',
      },
    },
  },
  tableColumns: {
    status: 'Status',
    slas: 'SLA',
    subject: 'Subject',
    id: 'ID',
    organization: 'Organization',
    groupName: 'Group',
    ticketForm: 'Form',
    createDate: 'Created at',
    assignee: 'Responsible',
    trt: 'TRT',
    vsSla: 'SLA comparison',
    viewLinkTooltip: 'Ctrl+click to open in a new tab',
  },
  pagination: {
    of: 'of',
  },
  ticketStatus: {
    new: 'New',
    open: 'Open',
    pending: 'Pending',
    hold: 'On Hold',
    solved: 'Solved',
    closed: 'Closed',
    inProgress: 'In progress',
  },
  ticketDetails: {
    title: 'Ticket details',
    noAccess: 'It seems like you do not have permission to access the details about this ticket.',
    formInfo: 'Form information',
    infoNotAvailable: 'Information not available',
    macro: {
      applyMacro: 'Apply macro',
    },
    ordersStatus: {
      orderPlaced: 'Order placed',
      processing: 'Processing',
      processingWithModifications: 'Processing with modifications',
      billed: 'Billed',
      outForDelivery: 'Out for delivery',
      delivered: 'Delivered',
      partiallyDelivered: 'Partially delivered',
      canceled: 'Canceled',
      declined: 'Declined',
      cancellationPending: 'Cancellation pending',
      paymentPending: 'Payment pending',
      readyForPickup: 'Ready for pickup',
    },
    pocInformation: {
      title: 'POC Information',
      name: 'Name',
      pocStatus: 'Status',
      challengeIds: 'Client code',
    },
    pocContact: {
      title: 'POC contact',
      ownerName: 'POC owner',
      ownerEmail: 'Email',
      telephone: 'Phone',
      taxId: 'Tax ID',
    },
    addressDistribution: {
      title: 'Address and distribution',
      address: 'Address',
      city: 'City',
      ddcName: 'Distribution center name',
    },
    salesRepresentative: {
      title: 'Sales representative',
      agentName: 'Sales representative name',
      plannedVisit: 'Expected visit',
      lastVisitStatus: 'Last visit status',
      lastVisit: 'Last visit date',
    },
    lastOrder: {
      title: 'Last order',
      orderNumber: 'Order ID',
      orderDate: 'Order date',
      amount: 'Amount',
      status: 'Status',
      source: 'Source',
      creationDate: 'Creation',
      deliveryDate: 'Delivery',
      paymentMethod: 'Payment method',
      totalAmount: 'Total amount',
      showProduct: 'Show products',
      noOrders: 'No orders made yet.',
      modal: {
        orderList: 'Product list',
        image: 'Image',
        productName: 'Product name',
        amount: 'Quantity',
        unitValue: 'Unit price',
        discount: 'Discount',
        totalValue: 'Total',
      },
    },
    ticketInfo: {
      title: 'Ticket information',
      id: 'Ticket ID:',
      updatedAt: 'Updated at',
      requester: 'Requester',
    },
    ticketFields: {
      assignee: 'Assignee',
      form: 'Form',
      resolutionGroup: 'Resolution group',
      reassign: 'Reassign',
      ticketUnassigned: 'Unassigned ticket',
      takeIt: 'Assign to name',
    },
    sla: {
      solveIn: 'Solve in',
      slaPaused: 'SLA Paused',
      expiration: 'Due date: ',
    },
  },
  bulkResolution: {
    modal: {
      openModalButton: 'Submit in Bulk',
      title: 'Fill out the fields to submit',
      subTitle: 'The selected tickets will be submitted in bulk.',
      form: 'Form',
      subCategory: 'Subcategory',
      numberOfTickets: 'Number of selecteds tickets',
      submit: 'Submit',
      cancel: 'Cancel',
      toast: {
        info: '{tickets} tickets submitted successfully.',
        error: `The tickets couldn't be submitted. Please try again`,
      },
      notAllowed: {
        title: 'Action not allowed',
        subTitle:
          'You do not have permission to edit the following tickets. You can continue without these tickets, or go back and try editing another selection.',
        cancel: 'Go back',
        submit: 'Continue without selected',
        allTicketsTitle: 'Action not allowed',
        allTicketsSubTitle: `You don't have permission to edit any of the selected tickets. Try again with other tickets.`,
        allTicketsCancel: 'Got it',
      },
    },
  },
  visitStatus: {
    OPEN: 'Open',
    PENDING: 'Pending',
    CLOSED: 'Closed',
    NOT_COMPLETED: 'Not Completed',
    CANCELLED: 'Cancelled',
  },
  comments: {
    internal: 'Internal',
    publicResponse: 'Public response',
    internalNote: 'Internal note',
  },
  chooseTicketStatus: 'Ticket status',
  submitButton: 'Submit',
  client: 'Client',
  toastMessages: {
    info: 'Updates successfully submitted.',
    error: 'Something went wrong. Please try again.',
    searchTicketError: 'No results found. Try searching for another ticket number.',
    unauthorizedSearchTicketError:
      'You do not have access to the ticket you searched. Search for a different ticket ID number.',
    searchPocError:
      'This ID has more than one POC associated to it. Please type a unique identifier.',
    unauthorizedSearchPocError:
      'The POC you searched for is not available to you. Look for a different tax ID number.',
    fileExceedsSizeLimitError: 'The file selected exceeds the 50MB limit.',
    fileError: 'Something went wrong with the upload of the file(s) {fileNames}. Please try again.',
    attachmentsWarning:
      'The attachment sent is being uploaded to the server,  so it will take a few minutes to be seen in the message feed.',
    reassignGroupSuccess: 'Reassign successfully submitted',
    reassignGroupError: 'Reassignment went wrong. Please try again.',
    unassociatedVendorIdPoc: 'You do not have access to the searched POC.',
    unassociatedVendorIdTicket: 'You do not have access to the searched ticket.',
  },
  searchBarTicket: {
    messageTicket: 'Search for a Ticket ID to access it directly.',
    messagePoc: 'Search for tax ID to access a POC directly',
    poc: 'POC',
  },
  filter: {
    title: 'Filter',
    apply: 'Apply',
    cancel: 'Cancel',
  },
  previewTickets: {
    title: 'Comments',
    lastComments: 'Last comments',
    viewButton: 'See ticket',
    totalComments: 'Total of comments: ',
    emptyComments: 'No additional comments',
  },
  noResults: 'No results',
  loading: 'Loading…',
  ticketPriorization: {
    title: 'Ticket prioritization',
    ticketsByType: 'Tickets by type',
    allTickets: 'All tickets',
    byGroups: 'By group',
    byForms: 'By form',
    total: 'Total',
    paused: 'Paused',
    expired: 'Expired',
    tmr: 'TRT',
    warning: 'Caution',
    neutral: 'Neutral',
    openAll: 'Show all',
    tooltipPaused:
      'The number of tickets that had their SLA paused, set with "pending" status. They are not being counted as expired tickets.',
    tooltipExpired: "Number of tickets that haven't been solved yet, with expired due date.",
    tooltipTmr:
      'The ongoing "total resolution time", which is the time between the date of creation and today.',
    buttonToTicketsSolve: 'Show tickets',
    days: 'days',
    day: 'day',
    expiringIn7DaysByGroup: 'Expiring in 7 days',
    today: 'today',
    none: 'None',
    filters: {
      alphabetical: 'A-Z',
      moreTickets: 'Greatest tickets qty',
    },
  },
  pocTicketHistory: {
    bdr: 'BDR name',
    taxId: 'Tax ID',
    challengeIds: 'Client code',
    status: {
      active: 'Active',
      blocked: 'Blocked',
      inactive: 'Inactive',
    },
    noAccess:
      'You do not have access to the ticket history from this POC. Try searching for a different POC.',
    noTicketsFound: 'It seems this POC has no tickets yet. Future tickets will be shown here.',
    noTicketsFoundTitle: 'No tickets found',
  },
  multiSelect: {
    itemsSelect: 'items selected',
  },
  slaStatus: {
    SLA_ON_TIME: 'On time',
    SLA_BREACHED: 'Late',
    NOT_FOUND: '-',
  },
  ticketHistory: {
    title: 'POC ticket history',
    solvedTickets: 'Solved',
    unSolvedTickets: 'To solve',
  },
  messages: {
    noAccess: 'Access denied',
    returnHome: 'Go to Home',
    returnPrevious: 'Go to Home',
  },
};

export default en;
