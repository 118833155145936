import MessageMap from '../i18n.d';

const pt: MessageMap = {
  ticketList: {
    title: 'Tickets a resolver',
    refreshButton: 'Recarregar',
    ticketsHaveUpdates: 'Seus tickets possuem atualizações. Por favor, clique em Recarregar.',
    filter: {
      title: 'Filtrado por',
      clearAll: 'Limpar tudo',
      filters: {
        groups: 'Grupos',
        forms: 'Formulário',
        formsLabels: {
          placeholderSelect: 'Selecione um formulário',
          message: 'Permitido enviar em lote (exceto "Todos os formulários")',
          allForms: 'Todos os formulários',
          note: 'Permite enviar em lote.',
        },
        formsSubcategory: 'Subcategoria',
        externalStatuses: 'Status',
        statusType: {
          new: 'Novo',
          onHold: 'Em espera',
          open: 'Aberto',
          pending: 'Pendente',
        },
        dateBreachAt: 'Data de vencimento',
        startDate: 'Data inicial',
        endDate: 'Data final',
        tooltip: {
          formsSubcategory: 'Alguns formulários permitem o envio de tickets em massa.',
          disabledFilter: 'Não é possível editar filtros em envios em massa.',
        },
      },
      errors: {
        endDateMustBeAfter: 'A data final deve ser depois da data inicial',
        maxRange7days: 'Intervalo máximo de datas: 7 dias',
      },
    },
  },
  tableColumns: {
    status: 'Status',
    slas: 'SLA',
    subject: 'Assunto',
    id: 'ID',
    organization: 'Organização',
    groupName: 'Grupo',
    ticketForm: 'Formulário',
    createDate: 'Criado em',
    assignee: 'Responsável',
    trt: 'TRT',
    vsSla: 'Comparação de SLA',
    viewLinkTooltip: 'Ctrl+click para abrir em uma nova aba',
  },
  pagination: {
    of: 'de',
  },
  ticketStatus: {
    new: 'Novo',
    open: 'Aberto',
    pending: 'Pendente',
    hold: 'Em espera',
    solved: 'Resolvido',
    closed: 'Fechado',
    inProgress: 'Aberto',
  },
  ticketDetails: {
    title: 'Detalhes do ticket',
    noAccess: 'Parece que você não tem permissão para acessar os detalhes desse pedido de suporte.',
    formInfo: 'Informações do formulário',
    infoNotAvailable: 'Informação não disponível.',
    macro: {
      applyMacro: 'Aplicar macro',
    },
    ordersStatus: {
      orderPlaced: 'Lançado',
      processing: 'Confirmado',
      processingWithModifications: 'Modificado',
      billed: 'Faturado',
      outForDelivery: 'Saiu para entrega',
      delivered: 'Entregue',
      partiallyDelivered: 'Entrega parcial',
      canceled: 'Cancelado',
      declined: 'Cancelado',
      cancellationPending: 'Cancelamento em andamento',
      paymentPending: 'Pagamento pendente',
      readyForPickup: 'Pronto para retirada',
    },
    pocInformation: {
      title: 'Informações do PDV',
      name: 'Nome',
      pocStatus: 'Status',
      challengeIds: 'Código do PDV',
    },
    pocContact: {
      title: 'Contato do PDV',
      ownerName: 'Proprietário do PDV',
      ownerEmail: 'E-mail',
      telephone: 'Telefone',
      taxId: 'CPF/CNPJ',
    },
    addressDistribution: {
      title: 'Endereço e distribuição',
      address: 'Endereço',
      city: 'Cidade',
      ddcName: 'Nome do centro de distribuição',
    },
    salesRepresentative: {
      title: 'Representante de vendas',
      agentName: 'Nome do representante de vendas',
      plannedVisit: 'Visita esperada',
      lastVisitStatus: 'Último status da visita',
      lastVisit: 'Data da última visita',
    },
    lastOrder: {
      title: 'Último pedido',
      orderNumber: 'ID do pedido',
      orderDate: 'Data do pedido',
      amount: 'Quantidade',
      status: 'Status',
      source: 'Origem',
      creationDate: 'Data do pedido',
      deliveryDate: 'Entrega',
      paymentMethod: 'Método de pagamento',
      totalAmount: 'Valor total',
      showProduct: 'Mostrar produtos',
      noOrders: 'Nenhum pedido feito ainda.',
      modal: {
        orderList: 'Lista de produtos',
        image: 'Imagem',
        productName: 'Nome do produto',
        amount: 'Quantidade',
        unitValue: 'Preço unitário',
        discount: 'Desconto',
        totalValue: 'Total',
      },
    },
    ticketInfo: {
      title: 'Informações do ticket',
      id: 'ID do ticket',
      updatedAt: 'Última atividade',
      requester: 'Solicitante',
    },
    ticketFields: {
      assignee: 'Responsável',
      form: 'Formulário',
      resolutionGroup: 'Grupo de resolução',
      reassign: 'Reatribuir',
      ticketUnassigned: 'Ticket não atribuído.',
      takeIt: 'Atribuir para mim',
    },
    sla: {
      solveIn: 'Resolver em',
      slaPaused: 'SLA Pausado',
      expiration: 'Data de vencimento: ',
    },
  },
  bulkResolution: {
    modal: {
      openModalButton: 'Enviar em massa',
      title: 'Preencha os campos para enviar',
      subTitle: 'Os tickets selecionados serão enviados em massa.',
      form: 'Formulário',
      subCategory: 'Sub Categoria',
      numberOfTickets: 'Número de tickets selecionados',
      cancel: 'Cancelar',
      submit: 'Enviar',
      toast: {
        info: '{tickets} tickets enviados com sucesso.',
        error: `Não foi possível enviar os tickets. Por favor, tente novamente.`,
      },
      notAllowed: {
        title: 'Ação não permitida',
        subTitle:
          'Você não tem permissão para editar os seguintes pedidos de suporte. Você pode continuar sem eles ou voltar e tentar editar outra seleção.',
        cancel: 'Voltar',
        submit: 'Continuar sem selecionar',
        allTicketsTitle: 'Ação não permitida',
        allTicketsSubTitle: `Você não tem permissão para editar nenhum dos pedidos de suporte selecionados. Tente novamente com outros pedidos de suporte.`,
        allTicketsCancel: 'Entendi',
      },
    },
  },
  visitStatus: {
    OPEN: 'Aberto',
    PENDING: 'Pendente',
    CLOSED: 'Fechado',
    NOT_COMPLETED: 'Não concluído',
    CANCELLED: 'Cancelado',
  },
  comments: {
    internal: 'Interna',
    public: 'Publica',
    publicResponse: 'Resposta pública',
    internalNote: 'Nota interna',
  },
  chooseTicketStatus: 'Status do ticket',
  submitButton: 'Enviar',
  client: 'Cliente',
  toastMessages: {
    info: 'Atualizações enviadas com sucesso.',
    error: 'Algo deu errado. Tente novamente.',
    searchTicketError: 'No results found. Try searching for another ticket number.',
    unauthorizedSearchTicketError:
      'Você não tem acesso ao pedido de suporte pesquisado. Procure outro ID de pedido de suporte.',
    searchPocError:
      'Este ID tem mais de um PDV associado a ele. Por favor, digite um identificador único.',
    unauthorizedSearchPocError:
      'O PDV que você pesquisou não está disponível para você. Procure outro CNPJ.',
    fileExceedsSizeLimitError: 'O arquivo selecionado ultrapassa o limite de 50MB.',
    fileError: 'Algo deu errado com o envio do arquivo(s) {fileNames}. Por favor, tente de novo.',
    attachmentsWarning:
      'O anexo enviado está sendo carregado no servidor, e por isso ele demorará alguns minutos para ser visto no feed de mensagens.',
    reassignGroupSuccess: 'Reatribuição efetuada com sucesso.',
    reassignGroupError: 'A reatribuição deu errado. Por favor, tente novamente.',
    unassociatedVendorIdPoc: 'Você não tem acesso ao POC pesquisado.',
    unassociatedVendorIdTicket: 'Você não tem acesso ao ticket pesquisado.',
  },
  searchBarTicket: {
    messageTicket: 'Busque por um Ticket ID para acessá-lo diretamente.',
    messagePoc: 'Pesquise pelo CPF/CNPJ para acessar diretamente o PDV.',
    poc: 'PDV',
  },
  filter: {
    title: 'Filtro',
    apply: 'Aplicar',
    cancel: 'Cancelar',
  },
  previewTickets: {
    title: 'Comentários',
    lastComments: 'Últimos comentários',
    viewButton: 'Ver ticket',
    totalComments: 'Total de comentários: ',
    emptyComments: 'Sem mais comentários',
  },
  noResults: 'Nenhum resultado',
  loading: 'Carregando…',
  ticketPriorization: {
    title: 'Priorização de tickets',
    ticketsByType: 'Tickets por tipo',
    allTickets: 'Todos os tickets',
    byGroups: 'Por grupo',
    byForms: 'Por formulário',
    total: 'Total',
    paused: 'Pausado',
    expired: 'Expirado',
    tmr: 'TMR',
    warning: 'Atenção',
    neutral: 'Neutro',
    openAll: 'Mostrar todos',
    buttonToTicketsSolve: 'Mostrar tickets',
    days: 'dias',
    day: 'dia',
    expiringIn7DaysByGroup: 'Expirando em 7 dias',
    today: 'hoje',
    none: 'Nenhum',
    filters: {
      alphabetical: 'A-Z',
      moreTickets: 'Maior quant. de tickets',
    },
    tooltipPaused:
      'O número de tickets que tiveram seu SLA pausado e com status "pendente". Eles não estão sendo contados como tickets expirados.',
    tooltipTmr:
      'O "tempo total de resolução" em andamento, que é o tempo entre a data de criação e hoje.',
    tooltipExpired:
      'Número de tickets que ainda não foram resolvidos com data de vencimento expirada.',
  },
  ticketHistory: {
    title: 'Histórico de tickets do PDV',
    solvedTickets: 'Fechado',
    unSolvedTickets: 'Aberto',
  },
  pocTicketHistory: {
    bdr: 'Nome do RN',
    taxId: 'CPF/CNPJ',
    challengeIds: 'Código do cliente',
    status: {
      active: 'Ativo',
      blocked: 'Bloqueado',
      inactive: 'Inativo',
    },
    noAccess:
      'Você não tem acesso ao histórico de pedidos de suporte deste PDV. Tente pesquisar outro PDV.',
    noTicketsFoundTitle: 'Nenhum pedido de suporte encontrado',
    noTicketsFound:
      'Parece que este PDV ainda não tem pedidos de suporte. Pedidos futuros serão exibidos aqui.',
  },
  multiSelect: {
    itemsSelect: 'itens selecionados',
  },
  slaStatus: {
    SLA_ON_TIME: 'Em dia',
    SLA_BREACHED: 'Atrasado',
    NOT_FOUND: '-',
  },
  messages: {
    noAccess: 'Acesso negado',
    returnHome: 'Ir para Página Inicial',
    returnPrevious: 'Ir para Página Inicial',
  },
};

export default pt;
