import React from 'react';

export type IEnvContext = {
  segmentKey: string;
  optimizelyKey: string;
  hotjarID: string;
  NODE_ENV: string;
  defaultVendorId: string;
};

const store: IEnvContext = {
  segmentKey: '',
  hotjarID: '',
  NODE_ENV: '',
  defaultVendorId: '',
  optimizelyKey: '',
};

export const EnvContext = React.createContext(store);
export const useEnvContext = () => React.useContext(EnvContext);

export const EnvProvider = ({ env, children }: { env: IEnvContext; children: React.ReactNode }) => (
  <EnvContext.Provider value={env}>{children}</EnvContext.Provider>
);
