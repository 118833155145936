import { Heading } from '@hexa-ui/components';
import { createGlobalStyle, styled } from 'styled-components';
import { MediumFontSize } from './const';

export default createGlobalStyle`
 :root {
    --purple-title: #'#886DD9';
  }
`;

export const CommonHeading = styled(Heading)`
  color: #886dd9;
  margin-bottom: 16px;
  ${MediumFontSize}
  font-family: 'Work Sans', sans-serif;
`;

